import styled, { css } from "styled-components";

interface ThemeProps {
  theme: string;
}

export const Container = styled.div<ThemeProps>`
    width: 100%;
    min-height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: ${({ theme }) =>
    (theme === 'darkmoon' &&
      css`
      linear-gradient(243deg, #2A2A2A 0%, #465672 54.17%, #596980 100%);
      `) || (theme === 'light' && css`
      linear-gradient(45deg, #61D5EE 0%, #8870EC 50%, #BC16EC 100%);
      `)};
    background-color: #FFF;
    background-size: 200%;
    background-position: left bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    animation: backgroundFloat 5s infinite;
    @keyframes backgroundFloat {
        0% { background-position: left bottom; }
        50% { background-position: right top; }
        100% { background-position: left bottom; }
    }

`

export const Logo = styled.div`

      height: 80px;
      width: 80px;

      .logo {
        transform: scale(0.4);
        stroke-dasharray: 850;
        stroke-dashoffset: 850;
        animation: drawPainelLogo 4s linear forwards infinite;
        color: #000;
      }

      .inside {

        animation: drawInsideLogo 4s linear forwards infinite;
      }

      .ellipse{
        transform: scale(0.4);
        animation: drawInsideEllipse 4s linear forwards infinite;
      }

      @keyframes drawPainelLogo {
        5%{stroke-dashoffset: 850}
        40%{stroke-dashoffset: 0}
        60%{stroke-dashoffset: 0}
        95% {
          stroke-dashoffset: 850;
        }
      }

      @keyframes drawInsideLogo {
        15%{stroke-dashoffset: 850}
        50% {
          stroke-dashoffset: 0;
        }
        85%{stroke-dashoffset: 850}
      }

      @keyframes drawInsideEllipse {
        0%{opacity: 0}
        10%{opacity: 1}
        90%{opacity: 1}
        100%{opacity: 0}

      }

`

export const Slider = styled.div`
    position: absolute;
    width: 350px;
    height: 3px;
    overflow: hidden;
    top: 60%;
    border-radius: 4px;
`

export const Line = styled.div`
    position: absolute;
    opacity: 0.9;
    background:#fff;
    width: 150%;
    height: 100%;
`


export const Subline = styled.div<ThemeProps>`
    position: absolute;
    background: ${({ theme }) =>
    (theme === 'darkmoon' &&
      css`
      #000000;
      `) || (theme === 'light' && css`
      #3DC0FF;
      `)};
    height: 100%;
`

export const Inc = styled(Subline) <ThemeProps>`
    animation: increase 2s infinite;
    @keyframes increase {
        from { left: -5%; width: 5%; }
        to { left: 130%; width: 100%;}
    }

`

export const Dec = styled(Subline) <ThemeProps>`
    animation: decrease 2s 0.5s infinite;
    @keyframes decrease {
        from { left: -80%; width: 80%; }
        to { left: 110%; width: 10%; }
    }
`
