import { createGlobalStyle, css } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.common.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.light.css';
import '@devexpress/analytics-core/dist/css/dx-querybuilder.css';
import 'devexpress-dashboard/dist/css/dx-dashboard.light.css';

import '../assets/css/theme.css';

interface ThemeProps {
  theme: string;
}

export default createGlobalStyle<ThemeProps>`
  :root {
    font-size: 70%;
    --color-outline: red;
  ${({ theme }) =>
    (theme === 'darkmoon' &&
      css`
        --background-container: #364652;
        --background-secundary: #465672;
        --background-primary: #596980;
        --background-primary-transparency: #364657cc;
        --background-themes: #28384a;
        --background-modal: #465672;
        --background-ul: #00a0a0;
        --background-li: #465672ee;
        --background-permissions-accordion: #364657cc;
        --background-health: #28384a;
        --background-menu: #36465a;
        --list-title: #36465a;
        --list-hover: #efff;
        --chevron-fill: #ffffffbb;
        --text-title-accordion: #ffffffdd;
        --text-title: #ffffffdd;
        --text-subtitle: #ffffffcc;
        --text-description: #ffffffbb;
        --text-placeholder: #ffffff88;
        --text-semi-dark: #ffffffdd;
        --icons: #aaccaa;
        --icons-hover: #15c7ae;
        --border-separator: #596980aa;
        --box-shadow: #0002;
        --button-submit: #d5a221cc;
        --button-success: #1ab07acc;
        --button-delete: #e06569cc;
        --button-reset: #777;
        --button-download-review: #1080c0cc;
        --button-reset: #777;
        --topbar-first: #465672;
        --topbar-second: #465672;
        --topbar-border: #16263290;
        --scrollbar-color: #15c7ae;
        --checkbox-color: #15c7ae;
        --placeholder-text-color: #bbb;
        --box-shadow-menu: #38383890;
        --background-topbar-input: #278;
        --topbar-placeholder: #fff8;
        --color-outline: #15c7ae;
        --button-disable: #808080;
        --button-download: #13cc99;
        --button-publish: #15a77e;
        --button-download-background: #13cc99cc;
        --goodHealthChk: #fffc;
        --badHealthChk: #ff5030;
        --loader-color: #3dc0ff;
        --modal-border: #bbbbbbaa;
        --checkbox-hover: #13cc9950;
        --checkbox-focus: #3aaa70;
        --tooltip-border: #779;
      `) ||
    (theme === 'dark' &&
      css`
        --background-container: #262622;
        --background-secundary: #464142;
        --background-primary: #59595a;
        --background-primary-transparency: #596980cc;
        --background-themes: #262622;
        --list-title: #2a2f2f;
        --list-hover: #efff;
        --chevron-fill: #ffffffbb;
        --text-title: #ffffffcb;
        --text-semi-dark: #ffffffdd;
        --border-separator: #596980aa;
        --box-shadow: #111a;
        --button-submit: #009979;
        --button-reset: #777;
        --topbar-first: #464142;
        --topbar-second: #464142;
        --topbar-third: #565152;
        --topbar-fourth: #464142;
        --topbar-border: #16263290;
        --scrollbar-color: #ccc7ce;
        --checkbox-color: #ccc7ce;
        --placeholder-text-color: #bbb;
        --box-shadow-right-menu: #0003;
        --background-topbar-input: #666;
        --topbar-placeholder: #fff7;
        --color-outline: #ccc;
        --button-publish: #15a77e;
      `) ||
    (theme === 'light' &&
      css`
        --background-container: #dddddd;
        --background-secundary: #f4f4f4;
        --background-primary: #fcfcff;
        --background-primary-transparency: #ddddddcc;
        --background-themes: #eee;
        --background-modal: #eeeeee;
        --background-ul: #0580cc;
        --background-li: #eeeeee;
        --background-permissions-accordion: #ffffff;
        --background-health: #3dc0ff;
        --background-menu: #f4f4f400;
        --list-title: #dddddd;
        --list-hover: #efff;
        --chevron-fill: #000000;
        --text-title-accordion: #ffffff;
        --text-title: #000d;
        --text-subtitle: #000c;
        --text-description: #000b;
        --text-placeholder: #00000088;
        --text-semi-dark: #333;
        --icons: #333333;
        --icons-hover: #0580cc;
        --border-separator: #bbbbbbaa;
        --box-shadow: #bbb9;
        --button-submit: #0580cc;
        --button-success: #0cc06acc;
        --button-delete: #f03529cc;
        --button-reset: #777;
        --button-download-review: #1080f0cc;
        --topbar-first: #0099e2;
        --topbar-second: #3dc0ff;
        --topbar-border: #dddddd;
        --scrollbar-color: #000000cc;
        --checkbox-color: #0580cc;
        --placeholder-text-color: #888;
        --box-shadow-right-menu: #0004;
        --box-shadow-menu: #44444470;
        --background-topbar-input: #eee;
        --topbar-placeholder: #0007;
        --color-outline: red;
        --button-disable: #ddd;
        --button-download: #15c7ae;
        --button-download-background: #15c7aecc;
        --button-publish: #0580cc;
        --goodHealthChk: #ffffff;
        --badHealthChk: #ff5050;
        --loader-color: #3dc0ff;
        --modal-border: #eeeeee;
        --checkbox-hover: #0580cc50;
        --checkbox-focus: #7cf;
        --tooltip-border: #aaaaaa;
      `)}


  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  input,
  select {
    font-family: 'Poppins', sans-serif;
  }

  .Toastify__toast {
    font-size: 1.5rem;
  }

  .dx-fileuploader-files-container{
    overflow-x: auto;
    height: 120px;
  }

`;
