import React, { useContext, useEffect, useState } from 'react';
import { Container, Dec, Inc, Line, Logo, Slider } from './styles';

import { ThemeContext } from 'src/store/themeContext';

export const SplashScreen: React.FC = () => {
  const { theme } = useContext(ThemeContext);

  const [animationControl, setAnimationControl] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimationControl(!animationControl);
    }, 4000);
  });

  return (
    <Container theme={theme}>
      <Logo>
        {!!animationControl ? (
          <svg
            width="174"
            height="200"
            viewBox="0 0 174 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="logo painel"
              fill="none"
              d="M3.02224 130.22L22.0983 111.144C23.8452 111.856 25.7554 112.247 27.7525 112.247C36.0368 112.247 42.7525 105.532 42.7525 97.2475C42.7525 95.2503 42.3608 93.3402 41.6494 91.5932L58.3457 74.8969C59.3818 75.3188 60.4753 75.6283 61.6102 75.8102L78.9222 145.058C75.327 147.795 73 152.124 73 157C73 165.284 79.7157 172 88 172C96.2843 172 103 165.284 103 157C103 153.218 101.598 149.762 99.2913 147.126L110.875 123.958C111.247 123.986 111.622 124 112 124C120.284 124 127 117.284 127 109C127 106.733 126.496 104.58 125.593 102.65L141.65 86.5927C143.58 87.4955 145.733 88 148 88C154.365 88 159.799 84.0382 161.979 78.4477L170.205 78.1214V121.132C170.205 137.924 161.247 153.44 146.705 161.836L110.103 182.968C95.5607 191.364 77.6444 191.364 63.1025 182.968L19.6865 157.902C9.71414 152.144 3.43988 141.671 3.02224 130.22ZM170.067 66.1222L161.503 66.4619C159.074 61.4551 153.944 58 148 58C139.716 58 133 64.7157 133 73C133 75.2667 133.504 77.4202 134.407 79.35L118.35 95.4073C116.42 94.5045 114.267 94 112 94C103.716 94 97 100.716 97 109C97 112.782 98.402 116.238 100.709 118.874L89.9435 140.405L73.0778 72.9418C76.673 70.2046 79 65.8757 79 61C79 52.7157 72.2843 46 64 46C55.7157 46 49 52.7157 49 61C49 62.9972 49.3917 64.9073 50.1031 66.6543L33.4068 83.3506C31.6598 82.6392 29.7497 82.2475 27.7525 82.2475C19.4683 82.2475 12.7525 88.9632 12.7525 97.2475C12.7525 99.2446 13.1443 101.155 13.8556 102.902L3 113.757V78.8675C3 62.076 11.9581 46.5601 26.5 38.1643L63.1025 17.0318C77.6444 8.63609 95.5607 8.63609 110.103 17.0318L155.251 43.0981C163.667 47.9574 169.144 56.5648 170.067 66.1222Z"
              stroke="white"
              strokeWidth="6"
            />
          </svg>
        ) : (
          <svg
            width="185"
            height="181"
            viewBox="0 0 185 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="logo inside"
              d="M38.8011 139.539C27.1187 126.421 20 109.004 20 89.8927C20 49.0829 52.4594 16 92.5 16C132.541 16 165 49.0829 165 89.8927C165 110.737 160.622 125.566 147 139"
              stroke="white"
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse className="ellipse" cx="92.5" cy="157.5" rx="22.5" ry="23.5" fill="white" />
          </svg>
        )}
      </Logo>
      <Slider>
        <Line />
        <Inc theme={theme} />
        <Dec theme={theme} />
      </Slider>
    </Container>
  );
};
