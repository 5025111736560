import axios from 'axios';
import { format } from 'date-fns';
import { default as packageData } from '../../package.json'

const api = axios.create({
	baseURL: process.env.REACT_APP_METRICS_LOG_IN_OUT,
});

export type RegisterProps = {
	acao: 'LOGIN' | 'LOGOFF'
	usuario: string
	email: string
	unidade: number
}

export function metricsRegister(params: RegisterProps): void {
	try {
		api.get('/v2/acessos/logInOut', {
			data: {
				...params,
				plataforma: 'inside-on-premises-frontend',
				versao: packageData.version,
				dataOcorrencia: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
			}
		})
		return
	} catch (error) {
		console.log(`Não possível realizar o registro no Metrics, erro\r\n: ${error}`)
	}
}
