import React, { Suspense, useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import api from 'src/services/api';

import { UserContext } from '../store/userContext';
import { SplashScreen } from 'src/components/SplashScreen';
import { ThemeContext } from '../store/themeContext';
import { ErrorPage } from 'insidesistemas-error-pages/dist/pages/ErrorPage'

import { TRoute } from '../infra/types';

import { default as packageData } from '../../package.json';
import { metricsRegister } from 'src/services/metrics';

const Template = React.lazy(async () => import('../components/Template'));
const LoginPage = React.lazy(async () => import('../pages/Login'));
const Dashboard = React.lazy(async () => import('../pages/Dashboard'));
const Permissions = React.lazy(async () => import('../pages/Permissions'));
const Publications = React.lazy(async () => import('../pages/Publications'));
const Revisions = React.lazy(async () => import('../pages/Revisions'));
const Store = React.lazy(async () => import('../pages/Store'));
//const ActiveUsers = React.lazy(async () => import('../pages/ActiveUsers'))

const S_TO_MS_FACTOR = 1000;

const AuthRoute: React.FC<TRoute> = ({ component: Component, ...props }: TRoute) => {
  const history = useHistory()
  const logged = JSON.parse(localStorage.getItem('context-on-premises') as string);
  const token = Cookies.get('token') as string;
  const tokenExpirationTimeInSeconds = Number(Cookies.get('tokenExpiration')) || 0;

  const isAuth =
    !!token &&
      !!logged &&
      tokenExpirationTimeInSeconds * S_TO_MS_FACTOR > Number(new Date().getTime())
      ? true
      : false;

  const isSessionExpired =
    (!!token || !!logged) &&
    tokenExpirationTimeInSeconds * S_TO_MS_FACTOR < Number(new Date().getTime());

  if (isSessionExpired) handleLogOut();

  async function handleLogOut() {

    const url = localStorage.getItem('url') as string;

    try {
      await api({ url, token }).post('/api/v1/Usuario/LogoutUsuario', {}, {
        headers: {
          Versao: packageData.version,
          Plataforma: packageData.name,
        }
      });

      const user = localStorage.getItem('context-on-premises')
      const userData = user ? JSON.parse(user) : null

      metricsRegister({
        acao: 'LOGOFF',
        usuario: userData.usuario,
        email: userData.email,
        unidade: 0
      })
    } catch (error) {
      console.error("Não foi possível registrar o log de logout")
    } finally {
      Cookies.remove('token');
      ['context-on-premises', 'onPremises:health', 'url', 'id-sessao'].forEach((localStorageItem) => {
        localStorage.removeItem(localStorageItem)
      })
      history.go(0)
    }

  }

  function checkAuthorization() {
    if (
      ['/permissions', '/store', '/publications', '/revisions'].includes(props.path) &&
      logged?.tipoUsuario >= 1
    ) {
      return true;
    } else if (
      ['/permissions', '/store', '/publications', '/revisions'].includes(props.path) &&
      logged?.tipoUsuario < 1
    ) {
      return false;
    }

    return true;
  }
  const hasAuthorizationToPage = checkAuthorization();

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={(render) => {
        if (isAuth && props.auth) {
          if (localStorage.getItem('on-premisses/dashboards-permissions') !== undefined) {
            localStorage.removeItem('on-premisses/dashboards-permissions');
          }
          if (hasAuthorizationToPage) {
            return (
              <Template>
                <Component />
              </Template>
            );
          } else {
            <Redirect to={{ pathname: props.redirect, state: { from: render.location } }} />;
          }
        } else if (!isAuth && !props.auth) {
          return <Component />;
        }

        return <Redirect to={{ pathname: props.redirect, state: { from: render.location } }} />;
      }}
    />
  );
};

function Routes() {
  const storedTheme = localStorage.getItem('onPremises: theme');
  const [user, setUser] = useState({});
  const [theme, setTheme] = useState(storedTheme ? storedTheme : 'light');
  const value3 = { theme, setTheme };
  return (
    <ThemeContext.Provider value={value3}>
      <UserContext.Provider value={{ user, setUser }}>
        <Suspense fallback={<SplashScreen />}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <AuthRoute path="/" exact auth component={Dashboard} redirect="/login" />
              <AuthRoute path="/permissions" exact auth component={Permissions} redirect="/login" />
              <AuthRoute path="/publications" exact auth component={Publications} redirect="/login" />
              <AuthRoute path="/revisions" exact auth component={Revisions} redirect="/login" />
              <AuthRoute path="/store" exact auth component={Store} redirect="/login" />
              {/*<AuthRoute path="/active-users" exact auth component={ActiveUsers} redirect="/login" />*/}
              <Route path="/error-page" exact component={ErrorPage} />
              {/* default */}
              <Route
                path="*"
                exact
                component={(props: any) => (
                  <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )}
              />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </UserContext.Provider>
    </ThemeContext.Provider>
  );
}

export default Routes;
