import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import reportWebVitals from './reportWebVitals';
import Styles from './styles';
import Routes from './routes';

import Toastify from './template/Toastify';

ReactDOM.render(
  <React.StrictMode>
    <Styles />
    <Toastify />
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
